export enum RouteMap {
  Root = '/',
  SignIn = '/login',
  SignUp = '/cadastro',
  ForgotPassword = '/esqueci-senha',
  RecoverPassword = 'recuperar-senha',
  Pin = '/pin',
  UserProfile = '/meu-perfil',
  ChangePassword = '/alterar-senha',
  ChangeEmail = '/meu-perfil/alterar-email',
  Notification = '/notificacoes',
  Logout = '/sair',
  Dashboard = '/home',
  Score = '/score',
  Objectives = '/meus-objetivos',
  ObjectiveDetail = '/meus-objetivos/detalhe',
  ObjectivesHistoric = '/meus-objetivos/historico',
  ObjectivesProgress = '/meus-objetivos/progresso',
  NegativeDebts = '/debitos-negativados',
  Offer = '/ofertas',
  OfferDetails = '/ofertas/:slug',
  ProductDetail = '/oferta-detalhada/:slug',
  MyFavorites = '/meus-favoritos',
  Help = '/ajuda',
  CpfLookout = '/consultas-cpf',
  BillsAndPayments = '/contas-pagamentos',
  BillsProductList = '/contas-pagamentos/categorias',
  BillsProductDetail = '/contas-pagamentos/produto',
  BillsInstallmentDetail = '/contas-pagamentos/parcela',
  MyDebts = '/debitos',
  MyDebtsAC = '/minhas-dividas',
  MyAgreementsAC = '/meus-acordos',
  MatchMaker = '/comparador',
  MatchMakerResult = '/comparador/sucesso',
  MonitoringCenter = '/alerta-cpf',
  CpfReport = '/relatorio-cpf',
  Otp = '/otp',
}

export const titleMap: Record<string, string> = {
  [RouteMap.Root]: 'Início',
  [RouteMap.SignIn]: 'Logar',
  [RouteMap.Otp]: 'Logar',
  [RouteMap.SignUp]: 'Cadastro',
  [RouteMap.ForgotPassword]: 'Esqueci a senha',
  [RouteMap.RecoverPassword]: 'Recuperar senha',
  [RouteMap.Pin]: 'Pin',
  [RouteMap.UserProfile]: 'Meu perfil',
  [RouteMap.ChangePassword]: 'Alterar senha',
  [RouteMap.ChangeEmail]: 'Alterar e-mail',
  [RouteMap.Notification]: 'Notificações',
  [RouteMap.Logout]: 'Sair',
  [RouteMap.Dashboard]: 'Home',
  [RouteMap.Score]: 'Score',
  [RouteMap.Objectives]: 'Meus Objetivos',
  [RouteMap.ObjectiveDetail]: 'Detalhes',
  [RouteMap.ObjectivesHistoric]: 'Meu histórico',
  [RouteMap.ObjectivesProgress]: 'Meu progresso',
  [RouteMap.NegativeDebts]: 'Débitos negativados',
  [RouteMap.Offer]: 'Ofertas',
  [RouteMap.OfferDetails]: 'Ofertas - Detalhes',
  [RouteMap.ProductDetail]: 'Oferta Detalhada',
  [RouteMap.Help]: 'Ajuda',
  [RouteMap.CpfLookout]: 'Consultas CPF',
  [RouteMap.BillsAndPayments]: 'Contas e Pagamentos',
  [RouteMap.BillsProductList]: 'Contas e pagamentos - Categorias',
  [RouteMap.BillsProductDetail]: 'Contas e pagamentos - Produto',
  [RouteMap.BillsInstallmentDetail]: 'Contas e pagamentos - Parcela',
  [RouteMap.MyDebts]: 'Minhas dividas',
  [RouteMap.MyDebtsAC]: 'Minhas dividas',
  [RouteMap.MatchMaker]: 'Comparador',
  [RouteMap.MonitoringCenter]: 'Alerta CPF',
  [RouteMap.CpfReport]: 'Relatório do meu CPF',
};
