import { createPortal } from 'react-dom';
import { Text, Button } from 'iq-blueberry';

import { useSmartScript } from '@common/hooks/useSmartScript';
import { Modal } from '../Modal';

import VerticalAppScoreDevice from '@common/assets/vertical-app-score-device.webp';

import './styles.scss';

type AppStoresModalProps = {
  showModal: boolean;
  onClose: () => void;
};

export const AppStoresModal: React.FC<AppStoresModalProps> = ({
  showModal,
  onClose,
}) => {
  const { generateOneLinkQRCode } = useSmartScript();

  return createPortal(
    <Modal
      showModal={showModal}
      onClose={onClose}
      mobile="center"
      desktop="center"
    >
      <div className="app-stores-modal">
        <div className="app-stores-modal__content">
          <div className="app-stores-modal__body">
            <div className="app-stores-modal__header">
              <Text as="h2" variant="heading-five" variantDesk="heading-three">
                Baixe nosso app
              </Text>
              <Text as="p" variant="body-medium" variantDesk="body-large">
                É só apontar seu leitor de QR Code ou câmera e baixar.
              </Text>
            </div>

            <div className="app-stores-modal__qr-code">
              <img src={generateOneLinkQRCode()} alt="QR Code" />
            </div>

            <Button
              size="large"
              type="primary"
              color="default"
              justify="center"
              loading={false}
              disabled={false}
              expand="x"
              onClick={onClose}
            >
              Voltar
            </Button>
          </div>
        </div>

        <div className="app-stores-modal__device">
          <img src={VerticalAppScoreDevice} alt="Celular com Score" />
        </div>
      </div>
    </Modal>,
    document.body
  );
};
